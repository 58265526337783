import { Component } from "../../extends";

export default (ctx) =>
	class Section extends Component(ctx) {
		mounted({
			index = () => null,
			nextCb = () => null,
			prevCb = () => null,
		}) {
			this.index = index;
			this.nextCb = nextCb;
			this.prevCb = prevCb;
			this.prevButton = this.el.querySelector(".c-contact__button--prev");
			this.nextButton = this.el.querySelector(".c-contact__button--next");
		}

		events() {
			if (this.nextButton) {
				this.nextButton.addEventListener("click", () => this.next());
			}

			if (this.prevButton) {
				this.prevButton.addEventListener("click", () => this.prev());
			}
		}

		enable() {
			this.el.classList.add("is-active");
		}

		disable() {
			this.el.classList.remove("is-active");
		}

		next() {
			if (this.nextCb) {
				this.nextCb({
					index: this.index,
				});
			}
		}

		prev() {
			if (this.prevCb) {
				this.prevCb({
					index: this.index,
				});
			}
		}
	};
