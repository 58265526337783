import { Component } from "../../extends";

export default (ctx) =>
	class Checkbox extends Component(ctx) {
		mounted({ checkedCb = () => null, uncheckedCb = () => null }) {
			this.input = this.el.querySelector(".c-pill__input");
			this.id = this.input.id;
			this.name = this.input.name;

			this.checkedCb = checkedCb;
			this.uncheckedCb = uncheckedCb;
		}

		events() {
			this.input.addEventListener("change", (e) => this.handleClick(e));
		}

		handleClick(e) {
			if (e.target.checked) {
				this.handleChecked();
			} else {
				this.handleUnchecked();
			}
		}

		enable() {
			this.input.checked = true;
		}

		disable() {
			this.input.checked = false;
		}

		handleChecked() {
			if (this.checkedCb) {
				this.checkedCb({
					item: this.id,
				});
			}
		}

		handleUnchecked() {
			if (this.uncheckedCb) {
				this.uncheckedCb({
					item: this.id,
				});
			}
		}
	};
