import { Component } from "../../extends";

export default (ctx) =>
	class RadioButton extends Component(ctx) {
		mounted() {
			this.input = this.el.querySelector(".c-radio-button__input");
			this.id = this.input.id;
			this.name = this.input.name;
			this.value = this.input.value;
		}

		events() {
			this.el.addEventListener("change", () => this.handleClick());
		}

		handleClick() {
			window.sessionStorage.setItem(this.name, this.value);
		}

		enable() {
			this.input.checked = true;
		}

		disable() {
			this.input.checked = false;
		}
	};
