import { Component } from "../extends";

export default () =>
	class Hamburger extends Component() {
		events() {
			this.el.addEventListener("click", () => this.toggle());
		}

		toggle() {
			this.el.classList.toggle("is-cross");

			if (!this.$Store.isMobileNavOpen) {
				this.enable();
			} else {
				this.disable();
			}
		}

		enable() {
			if (this.$Store.isMobileNavOpen) {
				return;
			}

			this.$Store.isMobileNavOpen = true;
			this.el.setAttribute("aria-label", "Close menu");
			this.el.setAttribute("aria-expanded", "true");

			this.$EE.emit("Hamburger:enable");
		}

		disable() {
			if (!this.$Store.isMobileNavOpen) {
				return;
			}

			this.$Store.isMobileNavOpen = false;
			this.el.setAttribute("aria-label", "Open menu");
			this.el.setAttribute("aria-expanded", "false");

			this.$EE.emit("Hamburger:disable");
		}
	};
