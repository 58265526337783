import { Component } from "../../extends";

export default (ctx) =>
	class Trigger extends Component(ctx) {
		mounted({ clickCb }) {
			this.clickCb = clickCb;
		}

		events() {
			this.el.addEventListener("click", (e) => this.handleClick(e));
		}

		handleClick(e) {
			if (e) {
				this.clickCb();
			}
		}
	};
