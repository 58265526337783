import { Component } from "../../extends";

export default (ctx) =>
	class CategoryTreatment extends Component(ctx) {
		events() {
			this.resizeObserver = new ResizeObserver(() => this.resize());
			this.el.addEventListener("click", () => this.toggle());
		}

		mounted({ toggleCb = () => null }) {
			this.toggleCb = toggleCb;

			this.index = this.el.getAttribute("data-treatment-id");
		}

		enable() {
			this.el.classList.add("is-active");
		}

		disable() {
			this.el.classList.remove("is-active");
		}

		toggle() {
			if (this.toggleCb) {
				this.toggleCb({
					index: this.index,
				});
			}
		}
	};
