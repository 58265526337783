import { Component } from "../../extends";
import CategoryTreatment from "./CategoryTreatment";
import TreatmentList from "./TreatmentList";

export default (ctx) =>
	class Treatments extends Component(ctx) {
		mounted() {
			this.categories = this.initCategories();
			this.treatmentLists = this.initTreatmentLists();

			this.initActive();
		}

		initCategories() {
			const els =
				this.el.querySelectorAll(".js-category-treatment") || [];

			return [...els].map(
				(el, index) =>
					new (CategoryTreatment(ctx))(el, {
						index,
						toggleCb: (params) => this.toggle(params),
					})
			);
		}

		initTreatmentLists() {
			const els = this.el.querySelectorAll(".js-treatment-list") || [];

			return [...els].map(
				(el, index) =>
					new (TreatmentList(ctx))(el, {
						index,
					})
			);
		}

		initActive() {
			this.categories.forEach((category, i) => {
				if (category.el.classList.contains("is-active")) {
					this.treatmentLists[i].enable();
				}
			});
		}

		toggle({ index }) {
			const currentIndex = index - 1;

			this.categories.forEach((category, i) => {
				if (
					i !== index &&
					category.el.classList.contains("is-active")
				) {
					category.disable();
					this.treatmentLists[i].disable();
				}
			});

			if (
				!this.categories[currentIndex].el.classList.contains(
					"is-active"
				)
			) {
				this.categories[currentIndex].enable();
				this.treatmentLists[currentIndex].enable();
			} else {
				this.categories[currentIndex].disable();
				this.treatmentLists[currentIndex].disable();
			}
		}
	};
