import { EventEmitter, Store } from "../singletons";

export const init = (selector, Module = null, params = {}) => {
	const els = document.querySelectorAll(selector) || [];
	const ctx = {
		EventEmitter,
		Store,
	};

	const instances = Module
		? [...els].map((el) => new (Module(ctx))(el, params))
		: [...els];

	if (instances.length === 1) {
		return instances[0] || null;
	}

	return instances || [];
};

export const getBounds = (el) => el.getBoundingClientRect() || {};

export const getBoundingBox = (el, keys) => {
	const bounds = getBounds(el);

	return keys ? keys.map((key) => bounds[key]) : bounds;
};
