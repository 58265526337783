import Swiper, { Navigation, Pagination } from "swiper";

import { Component } from "../extends";

export default () =>
	class Gallery extends Component() {
		mounted() {
			this.slider = this.el.querySelector(".c-gallery__slider");
			Swiper.use([Navigation, Pagination]);
			this.setSwiper();
		}

		setSwiper() {
			this.swiper = new Swiper(this.slider, {
				slidesPerView: 2.5,
				preventClicks: true,
				rewind: false,
				simulateTouch: true,
				spaceBetween: 20,
				setWrapperSize: true,
				pagination: {
					el: ".c-gallery__pagination",
					dynamicBullets: true,
				},
				navigation: {
					nextEl: ".c-gallery__button--next",
					prevEl: ".c-gallery__button--prev",
				},
				centerInsufficientSlides: true,
				breakpoints: {
					700: {
						spaceBetween: 30,
					},
				},
			});
		}
	};
