import { Component } from "../../extends";
import Trigger from "./Trigger";

export default (ctx) =>
	class Overlay extends Component(ctx) {
		mounted() {
			this.id = this.el.getAttribute("data-id");
			this.triggers = this.initTriggers();
			this.backdrop = this.el.querySelector(".c-overlay__backdrop");
			this.closeButton = this.el.querySelector(".c-overlay__close");
			this.content = this.el.querySelector(".c-overlay__content");
			this.toggleClass = "is-open";
		}

		events() {
			this.backdrop.addEventListener("click", () => this.close());
			this.closeButton.addEventListener("click", () => this.close());
			document.addEventListener("keydown", (e) =>
				this.keyInteractions(e)
			);
		}

		initTriggers() {
			const triggers = [];
			const triggerEl = document.querySelectorAll(
				`[data-overlay-id="${this.id}"]`
			);
			for (let i = 0; i < triggerEl.length; i += 1) {
				triggers.push(
					new (Trigger(ctx))(triggerEl[i], {
						clickCb: () => this.open(),
					})
				);
			}
			return triggers;
		}

		open() {
			// Open overlay
			this.el.classList.add(this.toggleClass);
			// Update the aria attributes
			this.closeButton.setAttribute("aria-expanded", "true");
			this.content.setAttribute("aria-hidden", "false");
			this.body.disableScroll();
		}

		close() {
			// Open overlay
			this.el.classList.remove(this.toggleClass);
			// Update the aria attributes
			this.closeButton.setAttribute("aria-expanded", "false");
			this.content.setAttribute("aria-hidden", "true");
			this.body.enableScroll();
		}

		keyInteractions(e) {
			const escape = e.key === "Escape";
			if (escape) {
				this.close();
			}
		}
	};
