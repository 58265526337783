export default class Content {
	constructor(el) {
		this.el = el;

		this.init();
	}

	init() {
		this.button = this.el.querySelector(".c-accordeon-item__button");
	}

	open() {
		if (this.button) {
			this.button.setAttribute("tabindex", "0");
		}
	}

	close() {
		if (this.button) {
			this.button.setAttribute("tabindex", "-1");
		}
	}
}
