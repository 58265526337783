import { Component } from "../extends";

export default () =>
	class Language extends Component() {
		events() {
			this.trigger.addEventListener("click", () => this.toggle());
			document.addEventListener("click", (e) => {
				if (!this.el.contains(e.target) && this.isVisible(this.el)) {
					this.close();
				}
			});
		}

		mounted() {
			this.trigger = this.el.querySelector(".c-language__trigger");
		}

		isVisible(el) {
			return (
				!!el &&
				!!(
					el.offsetWidth ||
					el.offsetHeight ||
					el.getClientRects().length
				)
			);
		}

		close() {
			if (this.el.classList.contains("is-open")) {
				this.el.classList.remove("is-open");
			}
		}

		toggle() {
			this.el.classList.toggle("is-open");

			if (this.el.classList.contains("is-open")) {
				this.trigger.setAttribute("aria-expanded", true);
			} else {
				this.trigger.setAttribute("aria-expanded", false);
			}
		}
	};
