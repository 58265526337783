import { Component } from "../extends";

export default () =>
	class Header extends Component() {
		events() {
			this.$EE.on("MobileNav:focus-last", () => this.focusHamburger());
			this.$EE.on("MobileNav:focus-first", () => this.focusLogo());
		}

		mounted() {
			this.logo = this.el.querySelector(".c-header__-logo");
			this.hamburger = this.el.querySelector(".c-header__hamburger");
		}

		onKeydown(e) {
			if (!this.$Store.isMobileNavOpen) {
				return;
			}

			const isShift = e.shiftKey;
			const focusedEl = document.activeElement;

			if (this.hamburger === focusedEl && isShift) {
				this.$EE.emit("Header:focus-out-hambuger");
				e.preventDefault();
			}
		}

		focusLogo() {
			this.logo.focus();
		}

		focusHamburger() {
			this.hamburger.focus();
		}
	};
