let instance = null;

class Body {
	constructor(el) {
		if (!instance) {
			instance = this;
		}

		this.el = el;
		this.isScrollDisabledClass = "has-scroll-disabled";
		this.scrollY = 0;
	}

	disableScroll(item = this.isScrollDisabledClass) {
		// Define scroll offset
		this.scrollY =
			(window.pageYOffset || this.el.scrollTop) -
			(this.el.clientTop || 0);
		this.el.classList.add(item);
		this.el.style.top = `-${this.scrollY}px`;
	}

	enableScroll(item = this.isScrollDisabledClass) {
		this.el.classList.remove(item, this.isScrollDisabledClass);
		// Disable scroll smooth when changing scroll position
		document.documentElement.style.scrollBehavior = "auto";
		this.el.style.top = null;
		window.scrollTo(0, this.scrollY);
		document.documentElement.style.scrollBehavior = null;
	}
}

export default new Body(document.body);
