import { Component } from "../../extends";
import Checkbox from "./Checkbox";

export default (ctx) =>
	class Checkboxes extends Component(ctx) {
		mounted() {
			this.storageItems = window.sessionStorage.getItem("message[What]");
			this.activeArr =
				this.storageItems !== null
					? this.stringToArr(this.storageItems)
					: [];
			this.checkboxes = this.initCheckboxes();

			this.enableActive();
		}

		initCheckboxes() {
			const els = this.el.querySelectorAll(".c-contact__checkbox") || [];

			return [...els].map(
				(el) =>
					new (Checkbox(ctx))(el, {
						checkedCb: (params) => this.handleAdd(params),
						uncheckedCb: (params) => this.handleRemove(params),
					})
			);
		}

		handleAdd({ item }) {
			this.activeArr.push(item);

			sessionStorage.setItem(
				"message[What]",
				this.arrToString(this.activeArr)
			);
		}

		handleRemove({ item }) {
			const index = this.activeArr.indexOf(item);
			if (index !== -1) {
				this.activeArr.splice(index, 1);
			}

			sessionStorage.setItem(
				"message[What]",
				this.arrToString(this.activeArr)
			);

			this.stringToArr(sessionStorage.getItem("message[What]"));
		}

		enableActive() {
			if (this.activeArr) {
				for (let i = 0; i < this.activeArr.length; i += 1) {
					for (let j = 0; j < this.checkboxes.length; j += 1) {
						if (this.checkboxes[j].id === this.activeArr[i]) {
							this.checkboxes[j].enable();
						}
					}
				}
			}
		}

		arrToString(arr) {
			const string = JSON.stringify(arr);
			return string
				.replace("[", "")
				.replaceAll('"', "")
				.replaceAll(",", ", ")
				.replace("]", "");
		}

		stringToArr(string) {
			return string.replaceAll(", ", ",").split(",");
		}

		disableAll() {
			for (let i = 0; i < this.checkboxes.length; i += 1) {
				this.checkboxes[i].disable();
			}

			this.activeArr = [];
		}
	};
