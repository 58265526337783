import { Component } from "../../extends";

export default (ctx) =>
	class TreatmentList extends Component(ctx) {
		mounted({ toggleCb = () => null }) {
			this.toggleCb = toggleCb;

			this.index = this.el.getAttribute("data-treatment-target");
		}

		enable() {
			this.el.classList.add("is-active");
		}

		disable() {
			this.el.classList.remove("is-active");
		}

		toggle() {
			if (this.toggleCb) {
				this.toggleCb();
			}
		}
	};
