import components from "./components";
import { init as initComponent } from "./utils/dom";

// Initialise & register all JavaScript components
const init = () => {
	initComponent(".js-accordeon-item", components.AccordeonItem);
	initComponent(".js-accordeon", components.Accordeon);
	initComponent(".js-language", components.Language);
	initComponent(".js-hamburger", components.Hamburger);
	initComponent(".js-header", components.Header);
	initComponent(".js-mobile-nav", components.MobileNav);
	initComponent(".js-hero", components.Hero);
	initComponent(".js-page-header", components.PageHeader);
	initComponent(".js-detail-header", components.DetailHeader);
	initComponent(".js-treatments", components.Treatments);
	initComponent(".js-body", components.Body);
	initComponent(".js-contact", components.Contact);
	initComponent(".js-gallery", components.Gallery);
	initComponent(".js-overlay", components.Overlay);
};

/**
 * Check if the document is ready to initialise the JavaScript components.
 * Interactive indicates: execute when document has finished loading, but
 * images, stylesheets and frames are still loading.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Document/readyState
 */
document.onreadystatechange = () => {
	if (document.readyState === "interactive") {
		init();
	}
};
