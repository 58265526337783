import { Component } from "../extends";
import Card from "./AccordeonItem/index";

export default (ctx) =>
	class Accordeon extends Component(ctx) {
		mounted() {
			this.accordeons = this.initAccordeons();
		}

		initAccordeons() {
			const els =
				this.el.querySelectorAll(
					".c-accordeon-item.js-accordeon-item"
				) || [];

			return [...els].map(
				(el, index) =>
					new (Card(ctx))(el, {
						index,
						toggleCb: (params) => this.toggle(params),
					})
			);
		}

		toggle({ index }) {
			const currentAccordeon = this.accordeons[index - 1];

			this.accordeons.forEach((accordeon) => {
				if (
					accordeon.index !== index &&
					accordeon.el.classList.contains("is-open")
				) {
					accordeon.disable();
				}
			});

			if (!currentAccordeon.el.classList.contains("is-open")) {
				currentAccordeon.enable();
			} else {
				currentAccordeon.disable();
			}
		}
	};
