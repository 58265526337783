import { Component } from "../../extends";
import Checkboxes from "./Checkboxes";
import HiddenField from "./HiddenField";
import List from "./List";
import RadioButton from "./RadioButton";
import Section from "./Section";
import Submit from "./Submit";

export default (ctx) =>
	class Contact extends Component(ctx) {
		mounted() {
			this.sections = this.initSections();
			this.radios = this.initRadios();
			this.checkboxes = new (Checkboxes(ctx))(
				this.el.querySelector(".c-contact__checkboxes")
			);
			this.lists = this.initLists();
			this.hiddenfields = this.initHiddenFields();
			this.submit = new (Submit(ctx))(
				this.el.querySelector(".c-contact__submit")
			);

			this.initActiveSection();
			this.initActiveRadios();

			this.setActiveLists();
		}

		initSections() {
			const els = this.el.querySelectorAll(".c-contact__section") || [];

			return [...els].map(
				(el, index) =>
					new (Section(ctx))(el, {
						index,
						nextCb: (params) => this.handleNext(params),
						prevCb: (params) => this.handlePrev(params),
					})
			);
		}

		initHiddenFields() {
			const els =
				this.el.querySelectorAll(".c-contact__hidden-field") || [];

			return [...els].map((el) => new (HiddenField(ctx))(el));
		}

		initRadios() {
			const els =
				this.el.querySelectorAll(".c-contact__radio-button") || [];

			return [...els].map((el) => new (RadioButton(ctx))(el));
		}

		initLists() {
			const els = this.el.querySelectorAll(".c-contact__list") || [];

			return [...els].map((el) => new (List(ctx))(el));
		}

		initActiveSection() {
			const activeStorrage = window.sessionStorage.getItem(
				"activeSection"
			);

			if (activeStorrage) {
				this.sections[activeStorrage].enable();
			} else {
				this.sections[0].enable();
			}
		}

		initActiveRadios() {
			const who = window.sessionStorage.getItem("message[Who]");
			const forWho = window.sessionStorage.getItem("message[For-who]");

			if (who) {
				const activeWho = this.radios
					.filter((radio) => radio.name === "message[Who]")
					.find((radio) => radio.id === who);
				activeWho.enable();
			}

			if (forWho) {
				const activeWho = this.radios
					.filter((radio) => radio.name === "message[For-who]")
					.find((radio) => radio.id === `${who}-${forWho}`);
				activeWho.enable();
			}
		}

		setActiveLists() {
			const who = window.sessionStorage.getItem("message[Who]");
			const forWho = window.sessionStorage.getItem("message[For-who]");

			this.lists.map((list) => list.disable());
			this.lists[0].enable();

			if (who) {
				this.lists
					.filter((list) => list.categoryId === who)
					.map((list) => list.enable());
			}

			if (forWho) {
				this.lists
					.filter((list) => list.subcategoryId === forWho)
					.map((list) => list.enable());
			}
		}

		handleNext({ index }) {
			this.sections[index].disable();
			this.sections[index + 1].enable();

			window.sessionStorage.setItem("activeSection", index + 1);

			this.setActiveLists();

			for (let i = 0; i < this.hiddenfields.length; i += 1) {
				this.hiddenfields[i].updateField();
			}
		}

		handlePrev({ index }) {
			this.sections[index].disable();
			this.sections[index - 1].enable();

			window.sessionStorage.setItem("activeSection", index - 1);

			if (index === 1) {
				window.sessionStorage.removeItem("message[For-who]");

				for (let i = 0; i < this.radios.length; i += 1) {
					if (this.radios[i].name === "message[For-who]") {
						this.radios[i].disable();
					}
				}
			}

			if (index === 2) {
				window.sessionStorage.removeItem("message[What]");

				this.checkboxes.disableAll();
			}

			this.setActiveLists();
			this.initActiveRadios();
		}
	};
