import { Component } from "../../extends";

export default (ctx) =>
	class HiddenField extends Component(ctx) {
		mounted() {
			this.name = this.el.getAttribute("name");
			this.el.value = window.sessionStorage.getItem(this.name);
		}

		updateField() {
			this.el.value = window.sessionStorage.getItem(this.name);
		}
	};
