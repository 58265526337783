import { Component } from "../../extends";

export default (ctx) =>
	class Submit extends Component(ctx) {
		events() {
			this.el.addEventListener("click", () => this.clearStorage());
		}

		clearStorage() {
			window.sessionStorage.clear();
		}
	};
