import Accordeon from "./Accordeon";
import AccordeonItem from "./AccordeonItem/index";
import Body from "./Body";
import Contact from "./Contact/index";
import DetailHeader from "./DetailHeader";
import Gallery from "./Gallery";
import Hamburger from "./Hamburger";
import Header from "./Header";
import Hero from "./Hero";
import Language from "./Language";
import MobileNav from "./MobileNav";
import Overlay from "./Overlay/index";
import PageHeader from "./PageHeader";
import Treatments from "./Treatments/index";

export default {
	Language,
	Hamburger,
	Header,
	Hero,
	MobileNav,
	AccordeonItem,
	Accordeon,
	PageHeader,
	DetailHeader,
	Treatments,
	Body,
	Contact,
	Gallery,
	Overlay,
};
