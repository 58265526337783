import { Component } from "../../extends";

export default (ctx) =>
	class List extends Component(ctx) {
		mounted() {
			this.categoryId = this.el.getAttribute("data-category-id");
			this.subcategoryId = this.el.getAttribute("data-subcategory-id");
		}

		enable() {
			this.el.classList.add("is-active");
		}

		disable() {
			this.el.classList.remove("is-active");
		}
	};
