import { Component } from "../../extends";
import Content from "./content";

export default (ctx) =>
	class AccordeonItem extends Component(ctx) {
		events() {
			this.resizeObserver = new ResizeObserver(() => this.resize());
			this.trigger.addEventListener("click", () => this.toggle());
		}

		mounted({ toggleCb = () => null }) {
			this.toggleCb = toggleCb;

			this.index = parseInt(this.el.getAttribute("data-index"), 10);
			this.trigger = this.el.querySelector(".c-accordeon-item__header");

			this.content = new Content(
				this.el.querySelector(".c-accordeon-item__content")
			);
		}

		enable() {
			this.content.open();

			this.el.classList.add("is-open");
		}

		disable() {
			this.content.close();

			this.el.classList.remove("is-open");
		}

		toggle() {
			if (this.toggleCb) {
				this.toggleCb({
					index: this.index,
				});
			}
		}
	};
