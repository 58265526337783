import { Component } from "../extends";

export default () =>
	class Body extends Component() {
		events() {
			this.$EE.on("Hamburger:enable", () => this.open());
			this.$EE.on("Hamburger:disable", () => this.close());
		}

		open() {
			this.el.classList.add("has-scroll-disabled");
		}

		close() {
			this.el.classList.remove("has-scroll-disabled");
		}
	};
