import gsap from "gsap";

import { Component } from "../extends";

export default () =>
	class PageHeader extends Component() {
		mounted() {
			this.contentItems = this.el.querySelectorAll(
				".c-page-header__content-item"
			);
			this.links = this.el.querySelector(".c-page-header__links");
			this.imageContainer = this.el.querySelector(
				".c-page-header__mask-container"
			);
			this.image = this.el.querySelector(".c-page-header__image");
		}

		animations() {
			gsap.set(this.contentItems, {
				y: "20%",
				opacity: 0,
			});

			gsap.set(this.links, {
				opacity: 0,
			});

			gsap.set(this.imageContainer, {
				x: "100%",
			});

			gsap.set(this.image, {
				x: "-100%",
			});

			this.anim = gsap.timeline();

			this.anim.to(
				this.contentItems,
				{
					y: 0,
					opacity: 1,
					ease: "sine.out",
					duration: 0.6,
					stagger: 0.15,
				},
				0.2
			);

			this.anim.to(
				this.imageContainer,
				{
					x: 0,
					ease: "expo.out",
					duration: 1.2,
				},
				0.2
			);

			this.anim.to(
				this.image,
				{
					x: 0,
					ease: "expo.out",
					duration: 1.2,
				},
				0.2
			);

			this.anim.seek(0);
			this.anim.play();
		}
	};
